<template>
  <div class="body_section">
    <profileHeader :showFilter="false" />
    <div class="body_content">
      <div class="main_content">
        <div class="form_subheader">
          <h5>
            <router-link to="/notes"
              ><img src="@/assets/images/return.svg" /></router-link
            >{{ pageTitle }}
          </h5>
        </div>
        <div class="form_section add_note">
          <ValidationObserver ref="addNoteForm">
            <form v-on:submit.prevent>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <ValidationProvider
                      name="Type"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div>
                        <b-form-radio-group
                          v-model="Payload.accessLevelId"
                          :options="note_access_level"
                          class="mb-3"
                          value-field="_id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-radio-group>
                      </div>
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <span class="divider"></span>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Subject</label>
                    <ValidationProvider
                      name="Subject"
                      rules="required|max:50"
                      v-slot="{ errors }"
                    >
                      <input
                        v-model="Payload.title"
                        type="text"
                        class="form-control"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Details</label>
                    <ValidationProvider
                      name="Details"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <textarea
                        v-model="Payload.description"
                        rows="4"
                        class="form-textarea"
                      ></textarea>
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <!-- <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label class="form_label">Category</label>
                                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                                        <div>
                                        <b-form-radio-group
                                        v-model="Payload.category"
                                        :options="options"
                                        class="mb-3"
                                        value-field="item"
                                        text-field="name"
                                        disabled-field="notEnabled"
                                        ></b-form-radio-group>

                                            </div>
                                    <span class="v-messages error-msg">{{ errors[0] }}</span>
                                    </ValidationProvider>

                                </div>
                            </div>
                        </div> -->

              <template v-if="Payload.accessLevelId == 3">
                <span class="divider"></span>
                <div class="row">
                  <div class="col">
                    <div class="clients">
                      <div class="form-group">
                        <label class="form_label">Assign To</label>
                        <div class="client_search">
                          <!-- <vue-autosuggest
                                                :suggestions="clientList"
                                                @input="onInputChange"
                                                @selected="onSelected"
                                                :limit="10"
                                                :liClass="'suggectli'"

                                                v-model="searchText" :input-props="{id:'autosuggest__input', placeholder:'Search Client'}">
                                            </vue-autosuggest>-->
                          <multiselect
                            v-model="Payload.taggedToDetails"
                            :value="'_id'"
                            :options="originalCliList"
                            :searchable="true"
                            :close-on-select="false"
                            :allow-empty="true"
                            :multiple="true"
                            label="name"
                            placeholder="Select Tagged To"
                            track-by="name"
                            @search-change="asyncFind"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                            >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} selected</span
                              >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                              ></span>
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <span class="divider"></span>
              <div class="row">
                <div class="col">
                  <div class="form-group marb10">
                    <label class="form_label">Documents</label>
                    <div class="upload_sec">
                      <uploadFile
                        @onUploadComplete="onUploadComplete"
                        :allowMultiple="true"
                        :acceptedFiles="'*'"
                        :takeIndex="false"
                        :payLoadKey="'document'"
                        :payLoadDocumentsKey="'documents'"
                      />
                    </div>
                    <ul class="uploaded-list">
                      <template v-for="(other, indx) in Payload.document">
                        <li :key="indx">
                          {{ other.name }}
                          <span @click="removeItem(indx, Payload.document)"
                            >X</span
                          >
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-actions">
                    <button class="secondary_btn cancel">
                      <router-link to="/notes"> Cancel </router-link>
                    </button>
                    <button
                      v-if="isEdit == false"
                      :disabled="checkForm"
                      @click="addNote()"
                      class="primary_btn"
                    >
                      Submit
                    </button>
                    <button
                      v-if="isEdit == true"
                      :disabled="checkForm"
                      @click="upDateNote()"
                      class="primary_btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Multiselect from 'vue-multiselect-inv';
/*import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { VueAutosuggest } from 'vue-autosuggest';


import FileUpload from "vue-upload-component/src";
*/

import profileHeader from '@/components/ProfileHeader.vue';
import footerView from '@/components/footer.vue';
import uploadFile from '@/components/uploadFile.vue';
//import { VueAutosuggest } from 'vue-autosuggest';
import _ from 'lodash';

export default {
  name: 'LNANotes',
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    profileHeader,
    footerView,
    //VuePhoneNumberInput,
    Multiselect,
    //  VueAutosuggest,
    //FileUpload
    uploadFile,
  },
  methods: {
    getNoteDetails() {
      const payload = {
        noteId: this.Payload.noteId,
      };
      this.$store
        .dispatch('getNoteDetails', payload)
        .then((res) => {
          this.Payload = res;
        })
        .catch((error) => {
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        });
    },
    asyncFind(query) {
      if (query == '' || query == null) {
        this.originalCliList = [];
        this.searchText = '';
      } else {
        this.originalCliList = [];
        this.searchText = query;
        this.getCliList();
      }
    },
    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: '',
          //"cntryId": 101,
          //"stateId": 665,
          //"cntryIds": [38], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch('getMaterData', payLoad)
        .then((res) => {
          let dt = res.list;
          //if(category =="llc_type"){
          _.forEach(dt, (obj, index) => {
            dt[index]['selected'] = false;
            if (category == 'note_access_level') {
              if (this.userdata.roleId == 5) {
                if (obj['_id'] != 3) {
                  this[category].push(dt[index]);
                }
              } else {
                this[category].push(dt[index]);
              }
            } else {
              this[category].push(dt[index]);
            }
          });

          //  }else{
          //     this[category] = dt;
          //  }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    onUploadComplete(data) {
      console.log(data);
      if (
        _.has(data, 'finalDocuments') &&
        _.has(data, 'payLoadKey') &&
        data['finalDocuments'].length > 0
      ) {
        _.forEach(data['finalDocuments'], (fl) => {
          this.Payload[data['payLoadKey']].push(fl);
        });
      }
    },
    removeItem(index, data) {
      data.splice(index, 1);
    },
    getCliList() {
      let postData = {
        getForNotes: true,
        getMasterData: true,
        filters: {
          title: this.searchText,
        },
        // getAssignList: true,
        // userId: this.userId,
        sorting: { path: 'name', order: -1 },
        page: 1,
        perpage: 100,
      };
      this.clientList = [];
      this.originalCliList = [];
      this.$store
        .dispatch('getAssignToList', postData)
        .then((response) => {
          this.originalCliList = response.list;
          var cliList = this.originalCliList.map((item) => item.name);
          if (cliList.length > 0) {
            this.clientList = [
              {
                data: cliList,
              },
            ];
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },

    addNote() {
      const valid = this.$refs['addNoteForm'].validate();
      console.log(valid);

      if (valid) {
        this.Payload.taggedTo = [];
        if (this.Payload.taggedToDetails.length > 0) {
          _.forEach(this.Payload.taggedToDetails, (item) => {
            this.Payload.taggedTo.push(item._id);
          });
        }
        this.$store
          .dispatch('addNote', this.Payload)
          .then((res) => {
            this.notify({
              message: res.message,
              title: 'Success',
              type: 'success',
            });
            this.Payload = {
              accessLevelId: 1,
              title: '',
              comments: [],
              document: [],
              description: '',
              taggedTo: [],
              taggedToDetails: [],
            };
            this.$refs['addNoteForm'].reset();
          })
          .catch((err) => {
            this.notify({ message: err, title: 'Error', type: 'error' });
          });
      }
    },
    //upDateNote
    upDateNote() {
      const valid = this.$refs['addNoteForm'].validate();

      if (valid) {
        this.Payload.taggedTo = [];
        if (this.Payload.taggedToDetails.length > 0) {
          _.forEach(this.Payload.taggedToDetails, (item) => {
            this.Payload.taggedTo.push(item._id);
          });
        }
        this.Payload['noteId'] = this.$route.params['noteId'];

        this.$store
          .dispatch('upDateNote', this.Payload)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: 'Success',
              type: 'success',
            });
            this.Payload = {
              accessLevelId: 1,
              title: '',
              comments: [],
              document: [],
              description: '',
              taggedTo: [],
              taggedToDetails: [],
            };
            this.$refs['addNoteForm'].reset();
          })
          .catch((err) => {
            this.notify({ message: err, title: 'Error', type: 'error' });
          });
      }
    },
  },
  data: () => ({
    pageTitle: 'Add Note',
    isEdit: false,
    note_access_level: [],
    options: [
      { item: 1, name: 'Internal' },
      { item: 2, name: 'Clients' },
      { item: 3, name: 'Custom' },
    ],

    clientList: [],
    userdata: null,
    documents: [],
    originalCliList: [],
    Payload: {
      title: '',
      comments: [
        /* {"comment": "Ln created12",	"statusId": 2} */
      ],
      document: [],
      description: '',
      taggedTo: [],
      taggedToDetails: [],
      accessLevelId: 1,
    },
    searchText: null,
  }),
  mounted() {
    this.userdata = this.$store.getters['auth/getuser'];
    this.getMaterData('note_access_level');
    if (_.has(this.$route.params, 'noteId')) {
      this.Payload.noteId = this.$route.params['noteId'];
      this.pageTitle = 'Edit Note';
      this.isEdit = true;
      this.getNoteDetails();
    }
    this.getCliList();
  },
  computed: {
    checkForm() {
      //Payload.accessLevelId
      if (
        this.Payload.title == '' ||
        this.Payload.description == '' ||
        (this.Payload.taggedToDetails.length <= 0 &&
          this.Payload.accessLevelId == 3)
      ) {
        return true;
      } else {
        console.log(this.Payload);
        return false;
      }
    },
  },
  beforeCreate() {
    this.userdata = this.$store.getters['auth/getuser'];
    // this.userdata = localStorage.getItem('user');
  },
};
</script>
